import React, { useState } from "react";

import {
  Typography,
  List,
  Collapse,
  ListItem,
  ListItemText,
} from "@mui/material";

import { ListItemIcon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQItem = ({ question, answer, expanded, setExpanded }) => {
  const handleToggle = () => {
    setExpanded(question === expanded ? null : question);
  };

  return (
    <div>
      <ListItem button onClick={handleToggle}>
        <ListItemIcon>
          {/* Use the expand more icon (replace it with your preferred icon) */}
          <ExpandMoreIcon
            style={{
              transition: "transform 0.3s",
              transform:
                expanded === question ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </ListItemIcon>
        <ListItemText primary={question} />
      </ListItem>
      <Collapse in={question === expanded} timeout="auto" unmountOnExit>
        <div style={{ paddingLeft: 70, paddingTop: 8, paddingBottom: 16 }}>
          <ListItemText secondary={answer} />
        </div>
      </Collapse>
    </div>
  );
};

function FAQ() {
  const [expandedItem, setExpandedItem] = useState(null);

  return (
    <div style={{ maxWidth: 800, margin: "auto", padding: 20 }}>
      {/* FAQ Section */}
      <Typography variant="h6">Frequently Asked Questions (FAQ)</Typography>
      <List>
        {/* FAQ Items */}
        <FAQItem
          question="Can I log in using an account other than Google?"
          answer="For now, it is only possible to log in using a Google account.
          We will, however, add more possibilities in the future.
          "
          expanded={expandedItem}
          setExpanded={setExpandedItem}
        />
        <FAQItem
          question="What happens with the data I enter?"
          answer="All the data you enter is stored in a database. 
          The raw data is only used for this project and will not be shared with any third party.
          This includes the service that is used to log you in, said service can not see your data.
          Aggregated data (definition: No conclusions can be drawn about the underlying individual data records) 
          will be used for research projects and publications."
          expanded={expandedItem}
          setExpanded={setExpandedItem}
        />
        <FAQItem
          question="What if I want to delete my account?"
          answer="Reach out to us via email at pv.reliability@gmail.com and we will
          delete your account."
          expanded={expandedItem}
          setExpanded={setExpandedItem}
        />
        <FAQItem
          question="How do I indicate the replacement of an inverter, which never broke?"
          answer="To indicate the replacement of an inverter you can go to the verfication tab. 
          There you can search for the inverter that has been replaced and click the verfiy status button, 
          where you can set the status of your inverter to 'replaced, without failure'. 
          Now all you need to do is to create a new inverter under the correct plant."
          expanded={expandedItem}
          setExpanded={setExpandedItem}
        />
        <FAQItem
          question="How do I indicate the replacement of an inverter with a second hand inverter?"
          answer="Go to the verfication tab. 
          Find the inverter that has been replaced and click the verfiy status button.
          Here you can set the status of your inverter either to 'replaced, without failure' or to 'broken' 
          depending on the reason you replace the inverter.
          Now all you need to do is to create a new inverter under the correct plant and check the 
          'Second Hand Inverter' box."
          expanded={expandedItem}
          setExpanded={setExpandedItem}
        />
        <FAQItem
          question="Can I add test data to pv-reliability?"
          answer="Yes, you’re welcome to challenge our platform! 
          Please use the word “Test” in the name of your plant and in the name of your inverter, so we can filter out test data."
          expanded={expandedItem}
          setExpanded={setExpandedItem}
        />
        {/* Add more FAQ items as needed */}
      </List>
    </div>
  );
}

export default FAQ;
