import React from "react";

import { Typography } from "@mui/material";

function TermsAndConditions() {
  return (
    <div style={{ maxWidth: 800, margin: "auto", padding: 20 }}>
      <Typography variant="h4">Terms & Conditions</Typography>
      <Typography variant="body2">
        Our Terms and Conditions were last updated on 28.05.2024.
      </Typography>
      <Typography variant="body1" marginTop={2}>
        Please read our terms and conditions ("terms") carefully. By using our
        website, you fully accept all of our terms. Should you disagree with any
        of our terms, you must not use the website.
        <br />
        We may change our terms at any time in the future. If we do so, the
        changes shall be effective upon posting and you agree to be obligated by
        the changes when using our website starting from the moment the changes
        are online. Therefore, it is important that you review the terms on a
        regular basis to ensure you do not miss any updates.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        User Responsibility
      </Typography>
      <Typography variant="body1">
        As the user, you confirm to be the solely responsible for the data you
        share on this website. In addition, you confirm to have the right to
        share said data.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        Usage of your data
      </Typography>
      <Typography variant="body1">
        You agree that your data may be used for scientific purposes and may be
        incorporated in scientific studies.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        Liability
      </Typography>
      <Typography variant="body1">
        We disclaim, to the permissible legal extent, any liability for any
        consequences, loss or damage that may arise from our use of your data or
        your use of our website.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        Disclaimer
      </Typography>
      <Typography variant="body1">
        The content and functionality of our website is carefully updated and
        maintained. However, we do not guarantee that all posted content on the
        website is correct, nor do we guarantee that all the functions on the
        website are error free and will never be interrupted.
      </Typography>
    </div>
  );
}

export default TermsAndConditions;
