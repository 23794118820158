import React, { useEffect, useState } from "react";
import { Card, CardContent, CardMedia, Typography, Grid } from "@mui/material"; // Import Material-UI components
import apiService from "../services/apiService";

function Statistics() {
  const [globalStats, setGlobalStats] = useState(null);

  // Fetches global statistics
  useEffect(() => {
    const fetchGlobalStats = async () => {
      try {
        const response = await apiService.getGlobalStats();
        setGlobalStats(response["stats"]);
      } catch (error) {
        console.error("Error fetching user stats:", error);
      }
    };

    fetchGlobalStats();
  }, []);

  return (
    <div style={{ padding: "16px" }}>
      <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
        Statistics
      </Typography>

      {/* 
      Kaplan Meier Diagrams from the older study 
      Is planned to be replaced with a generated 
      one using the actual data in the database
      */}
      <Grid container spacing={2} style={{ marginTop: 8 }}>
        <Grid item xs={12} md={9}>
          <Card sx={{ padding: 1 }} elevation={3}>
            <CardContent style={{ align: "center" }}>
              <Typography variant="h6">
                Kaplan-Meier Diagrams from the 2022 study
              </Typography>
              <Typography variant="body1">
                Both of the diagrams depict the survival probability of
                inverters over time. The first one shows the impact of
                optimizers in the plant and the second one displays the
                influence of the installation location.
              </Typography>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item>
                  <CardMedia
                    sx={{
                      width: { xs: 320, sm: 351 }, // sizing for the picture depending on the breakpoints
                      height: { xs: 237, sm: 260 },
                    }}
                    image={require("../images/kaplan_meier_opt.png")}
                  />{" "}
                </Grid>
                <Grid item>
                  <CardMedia
                    sx={{
                      width: { xs: 320, sm: 351 },
                      height: { xs: 237, sm: 260 },
                    }}
                    image={require("../images/kaplan_meier_place.png")}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          {globalStats && (
            <Card sx={{ padding: 1 }} elevation={3}>
              <CardContent>
                <Typography variant="h6">
                  All PV Systems on pv-reliability.ch
                </Typography>
                <Typography>
                  <strong>Active Plants: </strong>
                  {globalStats.plant_amount}
                </Typography>
                <Typography>
                  <strong>Active Inverters: </strong>
                  {globalStats.inverter_amount}
                </Typography>
                <Typography>
                  <strong>Total AC Power: </strong>
                  {globalStats.total_ac_power > 1000
                    ? Math.round(
                        (globalStats.total_ac_power / 1000 + Number.EPSILON) *
                          10
                      ) / 10
                    : Math.round(
                        (globalStats.total_ac_power + Number.EPSILON) * 10
                      ) / 10}
                  {globalStats.total_ac_power > 1000 ? " MW" : " kW"}
                </Typography>
                <Typography>
                  <strong>Total DC Power: </strong>
                  {globalStats.total_dc_power > 1000
                    ? Math.round(
                        (globalStats.total_dc_power / 1000 + Number.EPSILON) *
                          10
                      ) / 10
                    : Math.round(
                        (globalStats.total_dc_power + Number.EPSILON) * 10
                      ) / 10}
                  {globalStats.total_dc_power > 1000 ? " MW" : " kW"}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Statistics;
