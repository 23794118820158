import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Card, Grid } from "@mui/material";

const CreateDeleteDialog = ({ isOpen, onClose, onConfirm, title, text }) => {
  /*
  Opens a dialog that can be used to confirm stuff
  Used primarily to confirm deletions in out project
  */
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Card sx={{ p: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{text}</Typography>
        {/* Confirm and Cancel buttons positioned with spacing in between */}
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button color="secondary" onClick={onConfirm}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  );
};

export default CreateDeleteDialog;
