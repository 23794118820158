import { Box, CircularProgress, Typography, Link } from "@mui/material";
import { useIsAuthenticated } from "react-auth-kit";

const Login = () => {
  const isAuthenticated = useIsAuthenticated();

  // https://youtu.be/dQw4w9WgXcQ?si=tBh1UsSO3js__Pdj
  // If set to true, the site will not redirect to the oauth page
  // Thus, if you need to make changes where you need to take the site
  // offline, change this to true, nobody will be able to log in then.
  if (false) {
    return (
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
          height: "50vh",
        }}
      >
        <Typography variant="h5">
          Hi. We are currently making changes to the website.
          <br />
          While you wait, check out the{" "}
          <Link
            href="https://www.bfh.ch/pvlab"
            target="_blank"
            rel="noreferrer"
          >
            PVLab
          </Link>{" "}
          on the BFH website.
        </Typography>
      </Box>
    );
  }

  if (!isAuthenticated()) {
    const clientId =
      "1004074691500-56dm4172emj4sk981m36450uv9ggtgp8.apps.googleusercontent.com";
    const redirectUri =
      process.env.REACT_APP_REDIRECT_URI ||
      "http://localhost:3000/login-callback";
    // const redirectUri = "https://pv-reliability.ch/login-callback"
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=profile email`;

    // Redirect the user to the OAuth2 provider's login/authorization page
    window.location.href = authUrl;

    return (
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
};

export default Login;
