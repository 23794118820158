// ResponsiveAppBar.js

import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import { useIsAuthenticated, useSignOut } from "react-auth-kit";

import { useLocation } from "react-router-dom";

import apiService from "../services/apiService";

function ButtonAppBar() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const location = useLocation();

  // gets the user
  const [user, setUser] = useState({});
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiService.getAuth();

        if (response && response.hasOwnProperty("user")) {
          setUser(response.user);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching InverterTypes:", error);
      }
    };

    fetchUser();
  }, [location]);

  // If the user is an admin, the admin tab is also available
  let pages;
  if (user.user_role === "admin") {
    pages = ["Plants", "Update", "Statistics", "About", "FAQ", "Admin"];
  } else {
    pages = ["Plants", "Update", "Statistics", "About", "FAQ"];
  }
  const page_names = new Map();
  page_names.set("Plants", "Plants");
  page_names.set("Statistics", "Statistics");
  page_names.set("Update", "Verification");
  page_names.set("About", "About");
  page_names.set("FAQ", "FAQ");
  page_names.set("Admin", "Admin");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSignOut = () => {
    setTimeout(() => {
      signOut();
      navigate("/");
    }, 0);
  };

  return (
    <AppBar position="fixed" top={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Normal Layout */}

          {/* Title */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".07rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            pv-reliability.ch
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                // links to the pages
                <Link
                  key={page}
                  to={`/${page.toLowerCase()}`}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      {page_names.get(page)}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          {/* Layout for small windows */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: "0.07rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            pv-reliability.ch
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Link
                key={page}
                to={`/${page.toLowerCase()}`}
                style={{ textDecoration: "none" }}
              >
                <Button sx={{ my: 2, color: "white", display: "block" }}>
                  {page_names.get(page)}
                </Button>
              </Link>
            ))}
          </Box>

          {/* Login/Logout button with text or icon depending on the size and 
          authentication of the user */}
          <Box sx={{ flexGrow: 0 }}>
            {isAuthenticated() ? (
              <>
                <Button
                  onClick={handleSignOut}
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  Logout
                </Button>
                <Button
                  onClick={handleSignOut}
                  sx={{
                    my: 2,
                    color: "white",
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <LogoutIcon />
                </Button>
              </>
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <>
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: { xs: "flex", md: "none" },
                    }}
                  >
                    <LoginIcon />
                  </Button>
                </>
              </Link>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ButtonAppBar;
