// import react
import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import "./App.css";
// import react router dom
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

import { AuthProvider, RequireAuth } from "react-auth-kit";

import { Box, Container, ThemeProvider, createTheme } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";

import Plants from "./routes/Plants";
import PlantDetails from "./routes/PlantDetails";
import InverterDetails from "./routes/InverterDetails";
import StringDetails from "./routes/StringDetails";
import Update from "./routes/Update";
import Statistics from "./routes/Statistics";
import About from "./routes/About";
import Home from "./routes/Home";
import FAQ from "./routes/FAQ";
import Impressum from "./routes/Impressum";
import TermsAndConditions from "./routes/TandC";
import Admin from "./routes/Admin";

import apiService from "./services/apiService";
import ButtonAppBar from "./components/ButtonAppBar";
import OAuthCallback from "./routes/OAuthCallback";
import Login from "./routes/Login";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff5722",
    },
  },
});

// Default style for the pages
const contentStyle = {
  padding: "0px",
  paddingTop: "80px",
  maxWidth: "1200px", // Adjust the maximum width as needed
  margin: "0 auto", // Center the content
  width: "100%",
  flex: 1,
};

// Style for when breadcrumbs are present
const contentStyleWithBreadcrumbs = {
  ...contentStyle,
  paddingTop: 0,
};

// If a Site name must be changed it can be done here
const PATHNAME_MAPPING = {
  home: "Home",
  login: "Login",
  about: "About",
  faq: "FAQ",
  plants: "Plants",
  inverter: "",
  strings: "",
  Statistics: "Statistics",
  Update: "Update Status",
  Admin: "Admin",
  // Add more mappings as needed
};

function isUUID(str) {
  const uuidRegex =
    "[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}";
  const regex = new RegExp(uuidRegex);
  return regex.test(str);
}

async function getEntityName(entityType, entityId) {
  const response = await apiService.getName(entityId, entityType);
  return response;
}

function AppBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [breadcrumbs, setBreadcrumbs] = useState({});
  const breadcrumbsRef = useRef(breadcrumbs);

  const breadcrumbStyle = {
    padding: "8px",
    paddingTop: "80px",
  };

  const fetchData = useCallback(async () => {
    const newBreadcrumbs = {};

    const excludeWords = [
      "FAQ",
      "Home",
      "inverter",
      "Plants",
      "strings",
      "About",
      "statistics",
      "update",
      "Login",
      "impressum",
      "terms-conditions",
      "admin",
      "login",
      "login-callback",
    ];

    for (let index = 0; index < pathnames.length; index++) {
      const path = pathnames[index];

      if (isUUID(path)) {
        let entity_type = "";

        if (index === 1) {
          entity_type = "plant";
        } else if (index === 3) {
          entity_type = "inverter";
        } else if (index === 5) {
          entity_type = "string";
        }

        try {
          const response = await getEntityName(entity_type, path);
          const name = response.name;
          const crumb_url = `/${pathnames.slice(0, index + 1).join("/")}`;
          const crumb = { idx: index, url: crumb_url, name: name };
          newBreadcrumbs[index] = crumb;
        } catch (error) {
          console.error("Error fetching entity name:", error);
        }
      } else {
        const name = PATHNAME_MAPPING[path] || path;
        const crumb_url = `/${pathnames.slice(0, index + 1).join("/")}`;
        const crumb = { idx: index, url: crumb_url, name: name };
        newBreadcrumbs[index] = crumb;
      }
    }

    await Promise.all(Object.values(newBreadcrumbs));

    Object.values(newBreadcrumbs).sort((a, b) => a.idx - b.idx);

    Object.values(newBreadcrumbs).forEach((crumb) => {
      if (excludeWords.includes(crumb.name)) {
        delete newBreadcrumbs[crumb.idx];
      }
    });

    return newBreadcrumbs;
  }, [pathnames]);

  useEffect(() => {
    const fetchDataAndLogBreadcrumbs = async () => {
      const newBreadcrumbs = await fetchData();
      if (Object.values(newBreadcrumbs).length === 0) {
        console.log("none");
      }

      if (
        JSON.stringify(newBreadcrumbs) !==
        JSON.stringify(breadcrumbsRef.current)
      ) {
        setBreadcrumbs(newBreadcrumbs);
        breadcrumbsRef.current = newBreadcrumbs;
      }

      // log the breadcrumbs object
      console.log(breadcrumbsRef.current);
    };

    fetchDataAndLogBreadcrumbs();
  }, [fetchData]);

  return Object.values(breadcrumbs).length === 0 ? null : (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      sx={breadcrumbStyle}
    >
      {Object.values(breadcrumbs).map((crumb) => (
        <Link
          key={crumb.idx}
          to={crumb.url}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {crumb.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
}

function Footer() {
  /*
  Creates the footer which is found at the bottom of every page 
  */
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        left: 0,
        bottom: 0,
        width: "100%",
      }}
      component="footer"
    >
      <Container
        maxWidth="sm"
        sx={{
          marginTop: "auto",
          p: 4,
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center">
          {"© "}
          {new Date().getFullYear()}
          {" BFH. All rights reserved."}
          {" | "}
          <Link
            to={`/impressum`}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Impressum
          </Link>{" "}
          {" | "}
          <Link
            to={`/terms-conditions`}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Terms & Conditions
          </Link>
        </Typography>
      </Container>
    </Box>
  );
}

function Root(props) {
  /*
  Root div, everything is part of this div
  */
  return (
    <div id="content-div">
      <ButtonAppBar />
      <div
        sx={{
          padding: "0px",
          maxWidth: "1200px", // Adjust the maximum width as needed
          margin: "0 auto", // Center the content
          width: "100%",
        }}
      >
        <AppBreadcrumbs />
      </div>
      <Outlet />
      <Footer />
    </div>
  );
}

function ErrorPage() {
  // material ui based error page which giv4es a written error message and a button to go back to home page
  const errorStyle = {
    padding: "8px",
  };

  const buttonStyle = {
    padding: "8px",
  };

  return (
    <div style={errorStyle}>
      <Typography variant="h5" component="div" gutterBottom>
        Error 404: Page not found
      </Typography>
      <Typography variant="body1" gutterBottom>
        The page you are looking for does not exist
      </Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Typography variant="body1" style={buttonStyle}>
          Go back to home page
        </Typography>
      </Link>
    </div>
  );
}

const router = createBrowserRouter(
  /*
  Routes for all pages,
  some pages have specific styling 
  */
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Navigate to={"/home"} />} />
      <Route
        path={"/home"}
        element={
          <div
            style={{
              padding: "0px",
              margin: "0 auto", // Center the content
              width: "100%",
            }}
          >
            <Home />
          </div>
        }
      />
      <Route
        path={"/login"}
        element={
          <div style={contentStyle}>
            <Login />
          </div>
        }
      />
      <Route
        path={"/login-callback"}
        element={
          <div style={contentStyle}>
            <OAuthCallback />
          </div>
        }
      />
      <Route
        path={"/about"}
        element={
          <div style={contentStyle}>
            <About />
          </div>
        }
      />
      <Route
        path={"/faq"}
        element={
          <div style={contentStyle}>
            <FAQ />
          </div>
        }
      />
      <Route
        path={"/impressum"}
        element={
          <div style={contentStyle}>
            <Impressum />
          </div>
        }
      />
      <Route
        path={"/terms-conditions"}
        element={
          <div style={contentStyle}>
            <TermsAndConditions />
          </div>
        }
      />
      <Route
        path="/plants"
        element={
          <RequireAuth loginPath={"/login"}>
            <div style={contentStyle}>
              <Plants />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/plants/:id"
        element={
          <RequireAuth loginPath={"/login"}>
            <div style={contentStyleWithBreadcrumbs}>
              <PlantDetails />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/plants/:id/inverter/:inverterId"
        element={
          <RequireAuth loginPath={"/login"}>
            <div style={contentStyleWithBreadcrumbs}>
              <InverterDetails />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/plants/:id/inverter/:inverterId/strings/:stringId"
        element={
          <RequireAuth loginPath={"/login"}>
            <div style={contentStyleWithBreadcrumbs}>
              <StringDetails />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/Update"
        element={
          <RequireAuth loginPath={"/login"}>
            <div style={contentStyle}>
              <Update />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/statistics"
        element={
          <div style={contentStyle}>
            <Statistics />
          </div>
        }
      />
      <Route
        path="/admin"
        element={
          <RequireAuth loginPath={"/login"}>
            <div style={contentStyle}>
              <Admin />
            </div>
          </RequireAuth>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

function App() {
  /*
  Main app called by index.html
  */
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider
        authType={"Bearer"}
        authName={"_auth"}
        authStorageType={"cookie"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
      >
        <RouterProvider router={router}>
          <Outlet />
        </RouterProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
