import React from "react";

import { Typography, Link } from "@mui/material";

function Impressum() {
  return (
    <div style={{ maxWidth: 800, margin: "auto", padding: 20 }}>
      <Typography variant="h4">Legal Notice</Typography>

      <Typography variant="h6" marginTop={2}>
        Contact
      </Typography>
      <Typography variant="body1">
        Labor für Photovoltaiksysteme <br />
        Berner Fachhochschule (BFH) <br />
        Christof Bucher <br />
        Jlcoweg 1 <br />
        CH-3400 Burgdorf
      </Typography>

      <Typography variant="h6" marginTop={2}>
        Liability
      </Typography>
      <Typography variant="body1">
        The content of our website has been created with the greatest care.
        However, we cannot accept any liability for the accuracy, completeness
        and topicality of the content. We expressly reserve the right to change,
        delete or temporarily not publish content in whole or in part at any
        time without prior notice. Liability claims for damages of a material or
        immaterial nature arising from access to or use or non-use of the
        published information, misuse of the connection or technical faults are
        excluded.
      </Typography>

      <Typography variant="h6" marginTop={2}>
        References and links
      </Typography>
      <Typography variant="body1">
        References and links to third-party websites are outside our area of
        responsibility. Access to and use of such websites is at the user’s own
        risk. We expressly declare that we have no influence whatsoever on the
        design, content and offers of the linked websites. Information and
        services on linked websites are entirely the responsibility of the
        respective third party. No responsibility is accepted for such websites.
      </Typography>

      <Typography variant="h6" marginTop={2}>
        Copyright
      </Typography>
      <Typography variant="body1">
        The information contained on the website is made available to the
        public. Downloading or copying content, images, photos or other files
        does not transfer any rights to the content. The copyright and all other
        rights to content, images, photos or other files on the website belong
        exclusively to us or the specifically named rights holders. The written
        consent of the copyright holders must be obtained in advance for the
        reproduction of any elements.
        <br />
        The geolocation services and the map in the plant section contain
        information from OpenStreetMap, which is made available{" "}
        <Link
          href="https://www.openstreetmap.org/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </Link>
        , under the{" "}
        <Link
          href="https://opendatacommons.org/licenses/odbl/1-0/"
          target="_blank"
          rel="noreferrer"
        >
          Open Database License (ODbL)
        </Link>
        .
      </Typography>

      <Typography variant="h6" marginTop={2}>
        Last Update
      </Typography>
      <Typography variant="body1">16.05.2024</Typography>
    </div>
  );
}

export default Impressum;
