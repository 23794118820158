import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import CreatePlantDialog from "../components/CreatePlantDialog";
import PlantCard from "../components/Plant/PlantCard";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Collapse,
  CardActionArea,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import UserStats from "../components/Plant/UserStats";

function Plants() {
  const [plants, setPlants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [activePlantsOpen, setActivePlantsOpen] = useState(true);
  const [inactivePlantsOpen, setInactivePlantsOpen] = useState(false);

  // fetches the plants
  const fetchPlants = async () => {
    try {
      const response = await apiService.getPlants();

      if (response) {
        setPlants(response);
        setIsLoading(false);
      } else {
        console.error("Invalid response format. 'plants' property not found.");
      }
    } catch (error) {
      console.error("Error fetching plants:", error);
    }
  };

  useEffect(() => {
    fetchPlants();
  }, []);

  const handleButtonClick = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Use theme and media queries to set responsive heights
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  // Define responsive heights for different breakpoints
  const cardHeight = isXs ? 120 : isSm ? 120 : isMd ? 130 : isLg ? 160 : 130;

  return (
    <div style={{ padding: 16 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          Plants{" "}
        </Typography>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={9}>
          <CreatePlantDialog
            isOpen={isDialogOpen}
            onClose={handleCloseDialog}
          />

          {/* show info whlie loading then another widget */}
          {!isLoading && (
            <>
              <div>
                {/* Active Plants Section */}
                <Button
                  onClick={() => setActivePlantsOpen(!activePlantsOpen)}
                  sx={{ marginBottom: 1 }}
                >
                  {activePlantsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  {activePlantsOpen
                    ? "Hide Active Plants"
                    : "Show Active Plants"}
                  {" ("}
                  {plants.active_plants
                    ? Object.keys(plants.active_plants).length
                    : 0}
                  {")"}
                </Button>
                <Collapse in={activePlantsOpen} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    flexDirection="row"
                    alignItems="stretch"
                  >
                    {/* Creates the plant grid, one card for each plant */}
                    {plants.active_plants.map((plant) => (
                      <PlantCard
                        key={plant.id}
                        plant={plant}
                        height={cardHeight}
                      />
                    ))}

                    {/* Empty card with a plus symbol to add a new plant */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Card
                        elevation={3}
                        style={{
                          height: cardHeight,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CardActionArea
                          onClick={handleButtonClick}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <CardContent>
                            <AddIcon fontSize="large" />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Collapse>
              </div>
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <hr />
              </div>
              <div>
                {/* Inactive Plants Section */}
                <Button
                  onClick={() => setInactivePlantsOpen(!inactivePlantsOpen)}
                  sx={{ marginBottom: 1 }}
                >
                  {inactivePlantsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  {inactivePlantsOpen
                    ? "Hide Inactive Plants"
                    : "Show Inactive Plants"}
                  {" ("}
                  {plants.inactive_plants
                    ? Object.keys(plants.inactive_plants).length
                    : 0}
                  {")"}
                </Button>

                <Collapse in={inactivePlantsOpen} timeout="auto" unmountOnExit>
                  <Grid container spacing={2}>
                    {plants.inactive_plants.map((plant) => (
                      <PlantCard key={plant.id} plant={plant} />
                    ))}
                  </Grid>
                </Collapse>
              </div>
            </>
          )}

          {plants.length === 0 && (
            <Typography variant="body1">No plants found</Typography>
          )}
        </Grid>

        {/* Statistics Cards */}
        <Grid item xs={12} md={3}>
          <UserStats />
        </Grid>
      </Grid>
    </div>
  );
}

export default Plants;
