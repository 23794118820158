import React from "react";

import { Typography, Grid, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

function Home() {
  const imageURL = require("../images/inverter_image.jpg");

  return (
    /* 
    The styling of this page was a pain
    and it is rather fragile, so removing
    some of the styling might break everything 
    */
    <Grid
      container
      sx={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${imageURL})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid item xs={12} md={4} minWidth={400}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            height: "50%",
            mt: "10%",
            ml: { xs: "0px", md: "20%" },
            padding: 5,
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              color="primary"
              fontWeight={"bold"}
              align="center"
            >
              pv-reliability
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" color="white">
              We collect and share data on the reliability of inverters.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <div>
                <Typography variant="h6" align="center" color="white">
                  Do you operate a PV system? Share your data with us and find
                  out more about the life expectancy of inverters!
                </Typography>
              </div>
              <div>
                <Link to="/plants" style={{ textDecoration: "none" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ color: "white" }}
                  >
                    Login
                  </Button>
                </Link>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} hidden />
    </Grid>
  );
}

export default Home;
