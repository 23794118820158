import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Checkbox,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import apiService from "../../services/apiService";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const RoleManagementGrid = () => {
  const gridApi = useGridApiRef();

  // User Role Change
  const [users, setUsers] = useState([]);

  const [onlyAdmins, setOnlyAdmins] = useState(false);
  const [onlyNormalos, setOnlyNormalos] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await apiService.getUsers();

      if (response && response.hasOwnProperty("users")) {
        setUsers(response.users);
      } else {
        console.error("Invalid response format.");
      }
    } catch (error) {
      console.error("Error fetching InverterTypes:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserRoleChangeSubmit = async () => {
    // gets the index of all selected rows
    const ids = getSelectedRows();
    const toChange = users.filter((user) => ids.includes(user.id));

    // only the selected types are kept

    toChange.forEach((user) => {
      switchUser(user);
    });
  };

  const switchUser = async (email) => {
    /*
    Swithes the role of the selected user
    normalo -> admin and vice versa
    */
    try {
      const response = await apiService.makeAdmin(email);

      if (response && response.hasOwnProperty("id")) {
        console.log("Successfully changed role");
        fetchUsers();
      } else if (response.hasOwnProperty("message")) {
        console.error(response.message);
      } else {
        console.error("Invalid response when changing role.");
      }
    } catch (error) {
      console.error("Error creating invert type:", error);
    }
  };

  const getSelectedRows = () => {
    // Gets the ids of all selecte rows
    return Array.from(gridApi.current.getSelectedRows().keys());
  };

  // columns for the DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "email",
      headerName: "E-mail",
      width: 250,
    },
    { field: "user_role", headerName: "Role", width: 130 },
  ];

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant="h5">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <ManageAccountsIcon />
            <Box style={{ marginLeft: "10px" }}>Role Management</Box>
          </Box>
        </Typography>

        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            "align-items": "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={onlyAdmins}
              sx={{ color: "green" }}
              onClick={() => {
                if (!onlyAdmins) {
                  setOnlyNormalos(false);
                }
                setOnlyAdmins(!onlyAdmins);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Show admins only
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={onlyNormalos}
              sx={{ color: "green" }}
              onClick={() => {
                if (!onlyNormalos) {
                  setOnlyAdmins(false);
                }
                setOnlyNormalos(!onlyNormalos);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Show normalos only
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              rows={users.filter(
                (user) =>
                  (!onlyAdmins && !onlyNormalos) ||
                  (user.user_role === "admin" && onlyAdmins) ||
                  (user.user_role === "normalo" && onlyNormalos)
              )}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              disableRowSelectionOnClick
              apiRef={gridApi}
            ></DataGrid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <Button
              variant="contained"
              color="info"
              onClick={handleUserRoleChangeSubmit}
            >
              Switch Role
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RoleManagementGrid;
