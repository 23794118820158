// PlantCard.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, Typography, Grid } from "@mui/material";

const PlantCard = ({ plant, height }) => {
  /*
  Card for each plant at the plant overview 
  */
  return (
    <Grid item xs={12} sm={6} md={4} key={plant.id}>
      <Card elevation={3} style={{ height: height }}>
        <CardContent>
          <Link
            to={`/plants/${plant.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography
              noWrap
              variant="h6"
              component="div"
              sx={{ marginBottom: 1 }}
            >
              {plant.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Location:</strong> {plant.location_street},{" "}
              {plant.location_zip} {plant.location_city},{" "}
              {plant.location_country}
              <br />
              <strong>Commissioning Date:</strong> {plant.commissioning_date}
              <br />
            </Typography>
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PlantCard;
