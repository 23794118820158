import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { Box, CircularProgress } from "@mui/material";
import apiService from "../services/apiService";

const OAuthCallback = () => {
  const location = useLocation();
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const redirectUri =
    process.env.REACT_APP_REDIRECT_URI ||
    "http://localhost:3000/login-callback";

  const handleLogin = async (code) => {
    /*
    Tries to log in the user and receive the token
    needed for authentication afterwards
    */
    const result = await apiService.login(code, redirectUri);

    const token = result.token;

    if (token) {
      if (
        signIn({
          token: token,
          expiresIn: 120,
          tokenType: "Bearer",
        })
      ) {
        if (isAuthenticated()) {
          console.log("auth check successful");
        }
        // Redirect to the plants page after a delay of half a second
        setTimeout(() => {
          navigate("/plants");
        }, 500);
      } else {
        console.log("Login failed");
      }
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    // const state = queryParams.get("state");

    if (code) {
      handleLogin(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default OAuthCallback;
