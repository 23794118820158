import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import apiService from "../../services/apiService";

const OptimizerBroken = ({
  open,
  onClose,
  updatedInverterOptimizer,
  setUpdatedInverterOptimizer,
}) => {
  const [optimizerDialogErrors, setOptimizerDialogErrors] = useState({});

  const changeOptimizerDialogErrors = (name, value) => {
    /*
    Handles error in the optimizer broken dialog
    */
    setOptimizerDialogErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  const handleUpdateStatusChange = (e) => {
    const { name, value } = e.target;
    setUpdatedInverterOptimizer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateStatusConfirm = async () => {
    const errors = checkUpdateStatusChanges();
    if (Object.keys(errors).length !== 0) {
      console.log("Form has errors");
      console.log(optimizerDialogErrors);
      return;
    }

    try {
      await apiService.updateInverterStatuses({
        changes: [updatedInverterOptimizer],
      });
    } catch (error) {}
    onClose();
  };

  const checkUpdateStatusChanges = () => {
    /*
    Checks the validity of the input given to update the status
    */
    const errors = {};

    if (!updatedInverterOptimizer.newStatus) {
      errors.newStatus = "New status is required";
    } else if (updatedInverterOptimizer.newStatus === "operational") {
      return true;
    }
    if (
      updatedInverterOptimizer.newStatus !== "operational" &&
      !updatedInverterOptimizer.failureDate
    ) {
      errors.date = "Date is required";
    } else if (
      !updatedInverterOptimizer.failureDate.match(
        /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
      )
    ) {
      errors.date = "Date is not valid";
    } else if (
      (() => {
        const [day, month, year] =
          updatedInverterOptimizer.failureDate.split(".");
        const isoDateString = `${year}-${month}-${day}`;
        const date1 = new Date(isoDateString);

        const [day2, month2, year2] =
          updatedInverterOptimizer.earliest.split(".");
        const isoDateString2 = `${year2}-${month2}-${day2}`;
        const date2 = new Date(isoDateString2);

        return date1 < date2;
      })()
    ) {
      errors.date = "Date cannot be earlier than plant commissioning date";
    }
    if (
      updatedInverterOptimizer.newStatus === "broken" &&
      !updatedInverterOptimizer.description
    ) {
      errors.description = "Description is required";
    }
    setOptimizerDialogErrors(errors);
    return errors;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Inverter Status</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Number of broken optimizers*/}
            <TextField
              label="Number of broken optimizers"
              name="description"
              value={updatedInverterOptimizer.description}
              onChange={handleUpdateStatusChange}
              fullWidth
              margin="normal"
              type="number"
              error={Boolean(optimizerDialogErrors.description)}
              helperText={optimizerDialogErrors.description}
              displayEmpty
              onKeyDown={(e) =>
                e.key === "Enter" ? handleUpdateStatusConfirm() : null
              }
              sx={{
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                "input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
              }}
              onBlur={() => {
                if (!updatedInverterOptimizer.description) {
                  changeOptimizerDialogErrors(
                    "description",
                    "Number of optimizers is required"
                  );
                } else if (updatedInverterOptimizer.description < 0) {
                  changeOptimizerDialogErrors(
                    "description",
                    "Number of optimizers cannot be negative"
                  );
                } else {
                  changeOptimizerDialogErrors("description", "");
                }
              }}
            ></TextField>
          </Grid>

          {/* Date of failure */}
          <Grid item xs={12}>
            <TextField
              label={"Failure Date (DD.MM.YYYY)"}
              name="failureDate"
              value={updatedInverterOptimizer.failureDate}
              onChange={handleUpdateStatusChange}
              fullWidth
              margin="normal"
              error={Boolean(optimizerDialogErrors.date)}
              helperText={optimizerDialogErrors.date}
              displayEmpty
              onKeyDown={(e) =>
                e.key === "Enter" ? handleUpdateStatusConfirm() : null
              }
              onBlur={() => {
                if (!updatedInverterOptimizer.failureDate) {
                  changeOptimizerDialogErrors("date", "Date is required");
                } else if (
                  !updatedInverterOptimizer.failureDate.match(
                    /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
                  )
                ) {
                  changeOptimizerDialogErrors("date", "Date is not valid");
                } else if (
                  (() => {
                    const [day, month, year] =
                      updatedInverterOptimizer.failureDate.split(".");
                    const isoDateString = `${year}-${month}-${day}`;
                    const date1 = new Date(isoDateString);

                    const [day2, month2, year2] =
                      updatedInverterOptimizer.earliest.split(".");
                    const isoDateString2 = `${year2}-${month2}-${day2}`;
                    const date2 = new Date(isoDateString2);

                    console.log(date1);
                    console.log(date2);
                    console.log(date1 < date2);

                    return date1 < date2;
                  })()
                ) {
                  changeOptimizerDialogErrors(
                    "date",
                    "Date cannot be earlier than plant commissioning date"
                  );
                } else {
                  changeOptimizerDialogErrors("date", "");
                }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdateStatusConfirm} color="primary">
          Update Status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptimizerBroken;
