import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  Grid,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import apiService from "../services/apiService";
import ConfirmDeleteDialog from "../components/ConfirmDelete";
import CreateStringDialog from "../components/CreateStringDialog";

function StringDetails() {
  const { id, inverterId, stringId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [inverter, setInverter] = useState({});

  // String states
  const [isEditing, setIsEditing] = useState(false);
  const [string, setString] = useState({});
  const [stringEditValues, setStringEditValues] = useState({});
  const [stringEditErrors, setStringEditErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreateStringDialogOpen, setIsCreateStringDialogOpen] =
    useState(false);

  const [moduleDropdown, setModuleDropdown] = useState({});

  // Fetch module types for the dropdown
  useEffect(() => {
    const fetchModuleTypes = async () => {
      try {
        const response = await apiService.getModuleTypes();

        if (response && response.hasOwnProperty("types")) {
          setModuleDropdown(response.types);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching module types:", error);
      }
    };

    fetchModuleTypes();
  }, []);

  // fetches the string data
  const fetchData = async () => {
    try {
      const result = await apiService.getString(id, inverterId, stringId);
      setString(result.string);
      setStringEditValues(result.string);
    } catch (error) {
      console.error("Error fetching string details:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchInverter = async () => {
    try {
      const result = await apiService.getInverter(id, inverterId);
      setInverter(result.inverter);
    } catch (error) {
      console.error("Error fetching inverter:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchInverter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, inverterId, stringId]);

  const handleEditClick = () => {
    setStringEditValues(string);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setStringEditErrors({});
    setIsEditing(false);
    setStringEditValues(string);
  };

  const changeDialogErrors = (name, value) => {
    /*
    Changes the errors of some field
    */
    setStringEditErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    /*
    Gets called every time an input value of the edit 
    form gets changed
    */
    const { name, value } = e.target;

    if (name === "module_manufacturer_custom" && !value) {
      setStringEditValues((prev) => ({
        ...prev,
        module_manufacturer: "",
        module_manufacturer_custom: "",
        module_type: "",
        module_type_custom: "",
      }));
    }

    if (
      (name === "module_type_custom" && !value) ||
      name === "module_manufacturer"
    ) {
      setStringEditValues((prev) => ({
        ...prev,
        module_type: "",
        module_type_custom: "",
      }));
    }

    setStringEditValues((prevString) => ({
      ...prevString,
      [name]: value,
    }));
  };

  const handleSaveEdit = async () => {
    const formIsReady = checkStringCreateDialogErrors();
    if (!formIsReady) {
      console.log("There are errors in the form");
      console.log(stringEditErrors);
    }

    try {
      const response = await apiService.updateString(
        id,
        inverterId,
        stringId,
        getChangedProperties(stringEditValues)
      );
      if (response && response.string) {
        setStringEditValues(response.string);
        console.log("String details updated successfully");
      } else {
        console.error(
          "Invalid response format after update. 'string' property not found."
        );
      }
    } catch (error) {
      console.error("Error saving string details:", error);
    } finally {
      setIsEditing(false);
      fetchData();
    }
  };

  const checkStringCreateDialogErrors = () => {
    /*
    Checks if the input of the form values are valid.
    Sets the error below the fields if it is not the case.
    */

    if (stringEditValues.module_manufacturer_custom) {
      stringEditValues.module_manufacturer =
        stringEditValues.module_manufacturer_custom;
    }

    if (stringEditValues.module_type_custom) {
      stringEditValues.module_type = stringEditValues.module_type_custom;
    }

    const errors = {};
    if (!stringEditValues.name) {
      errors.name = "String name is required";
    }
    if (stringEditValues.numoptimizer < 0) {
      errors.numoptimizer = "Number of optimizers cannot be negative";
    }
    if (stringEditValues.dc_power_optimizer < 0) {
      errors.dc_power_optimizer = "Optimizer DC power cannot be negative";
    }
    /*
    if (!stringEditValues.dc_power_optimizer) {
      errors.dc_power_optimizer = "Optimizer DC power is required";
    }
    if (!stringEditValues.optimizer_type) {
      errors.optimizer_type = "Optimizer type is required";
    }
    if (!stringEditValues.optimizer_manufacturer) {
      errors.optimizer_manufacturer = "Optimizer manufacturer is required";
    }
    */

    if (!stringEditValues.nummodules) {
      errors.nummodules = "Number of modules is required";
    }
    if (stringEditValues.nummodules <= 0) {
      errors.nummodules = "Number of modules cannot be negative";
    }
    if (!stringEditValues.dc_power_per_module) {
      errors.dc_power_per_module = "Module DC power is required";
    }
    if (stringEditValues.dc_power_per_module <= 0) {
      errors.dc_power_per_module = "Module DC power cannot be negativ";
    }
    if (!stringEditValues.module_type) {
      errors.module_type = "Module type is required";
    }
    if (!stringEditValues.module_manufacturer) {
      errors.module_manufacturer = "Module manufacturer is required";
    }
    if (stringEditValues.orientation < 0) {
      errors.orientation = "Orientation cannot be negative";
    }
    if (stringEditValues.tilt < 0 || stringEditValues.titl > 90) {
      errors.orientation = "Orientation must be between 0 and 90 degrees";
    }
    if (stringEditValues.module_cable_length < 0) {
      errors.orientation = "Module cable length cannot be negative";
    }
    if (stringEditValues.module_cable_cross_section < 0) {
      errors.orientation = "Module cable cross section cannot be negative";
    }
    if (stringEditValues.optimizer_cable_length < 0) {
      errors.orientation = "Optimizer cable length cannot be negative";
    }
    if (stringEditValues.optimizer_cable_cross_section < 0) {
      errors.orientation = "Optimizer cable cross section cannot be negative";
    }
    if (stringEditValues.voltage_mpp < 0) {
      errors.orientation = "MPP voltage cannot be negative";
    }
    if (stringEditValues.voltage_oc < 0) {
      errors.orientation = "Open Circuit Voltage cannot be negative";
    }
    setStringEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const getChangedProperties = (stringEditValues) => {
    return Object.fromEntries(
      Object.entries(stringEditValues).filter(
        ([key, value]) => value !== string[key]
      )
    );
  };

  const handleDeleteClick = async () => {
    try {
      await apiService.deleteString(id, inverterId, stringId);
      console.log("String deleted successfully");
    } catch (error) {
      console.error("Error deleting string:", error);
    } finally {
      // navigate to inverter details page
      navigate(`/plants/${id}/inverter/${inverterId}`);
    }
  };

  const duplicateString = async (
    string,
    navigateToNewString = false,
    keep_name = false
  ) => {
    /*
    Duplicates a string with all its values
    */
    const stringCopy = { ...string };
    if (!keep_name) {
      stringCopy.name = string.name + " copy";
    }

    try {
      const response = await apiService.createString(
        id,
        inverterId,
        stringCopy
      );

      if (response && response.hasOwnProperty("id") && navigateToNewString) {
        navigate(`/plants/${id}/inverter/${inverterId}/strings/${response.id}`);
      }
    } catch (error) {
      console.error("Error duplicating new string:", error);
    }
  };

  return (
    <div>
      <ConfirmDeleteDialog
        isOpen={isDeleting}
        onClose={() => {
          setIsDeleting(false);
        }}
        onConfirm={handleDeleteClick}
        text="The string will be deleted completely! Are you sure you want to delete it?"
      />

      <CreateStringDialog
        isOpen={isCreateStringDialogOpen}
        onClose={() => {
          setIsCreateStringDialogOpen(false);
        }}
        plantId={id}
        inverterId={inverterId}
      />

      {!isLoading ? (
        <>
          <Card style={{ width: "100%", maxWidth: 1200 }} elevation={5}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" component="div">
                  Details {string.name}
                </Typography>
                {isEditing ? (
                  <>
                    <Button onClick={handleSaveEdit} color="primary">
                      Save
                    </Button>
                    <Button onClick={handleCancelEdit} color="secondary">
                      Cancel
                    </Button>
                  </>
                ) : inverter.status === "operational" ? (
                  <IconButton onClick={handleEditClick} color="primary">
                    <EditIcon />
                  </IconButton>
                ) : null}
              </div>
              {isEditing ? (
                <form>
                  {/* String Name */}
                  <TextField
                    label="String Name"
                    name="name"
                    value={stringEditValues.name}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.name)}
                    helperText={stringEditErrors.name}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    onBlur={() => {
                      if (!stringEditValues.name) {
                        changeDialogErrors("name", "String name is required");
                      } else {
                        changeDialogErrors("name", "");
                      }
                    }}
                  />

                  {/* Number of Modules */}
                  <TextField
                    label="Number of Modules"
                    name="nummodules"
                    type="number"
                    value={stringEditValues.nummodules}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.nummodules)}
                    helperText={stringEditErrors.nummodules}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (!stringEditValues.nummodules) {
                        changeDialogErrors(
                          "nummodules",
                          "Number of modules are required"
                        );
                      } else if (stringEditValues.nummodules < 0) {
                        changeDialogErrors(
                          "nummodules",
                          "Number of modules cannot be negative"
                        );
                      } else {
                        changeDialogErrors("num_modules", "");
                      }
                    }}
                  />

                  {/* Power per Module */}
                  <TextField
                    label="Power per Module in W"
                    name="dc_power_per_module"
                    type="number"
                    value={stringEditValues.dc_power_per_module}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.dc_power_per_module)}
                    helperText={stringEditErrors.dc_power_per_module}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">W</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (!stringEditValues.dc_power_per_module) {
                        changeDialogErrors(
                          "dc_power_per_module",
                          "DC power per module is required"
                        );
                      } else if (stringEditValues.dc_power_per_module < 0) {
                        changeDialogErrors(
                          "dc_power_per_module",
                          "DC power per module cannot be negative"
                        );
                      } else {
                        changeDialogErrors("dc_power_per_module", "");
                      }
                    }}
                  />

                  {/* Module Manufacturer*/}
                  {stringEditValues.module_manufacturer ===
                    "-- Manufacturer not on the list --" ||
                  stringEditValues.module_manufacturer_custom ? (
                    <TextField
                      label="Module Manufacturer"
                      name="module_manufacturer_custom"
                      value={stringEditValues.module_manufacturer_custom}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      error={Boolean(
                        stringEditErrors.module_manufacturer_custom
                      )}
                      helperText={stringEditErrors.module_manufacturer_custom}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSaveEdit() : null
                      }
                      onBlur={() => {
                        if (!stringEditValues.module_manufacturer_custom) {
                          stringEditValues.module_manufacturer = "";
                        } else {
                          changeDialogErrors("module_manufacturer", "");
                        }
                      }}
                    />
                  ) : (
                    <TextField
                      select
                      label="Module Manufacturer"
                      name="module_manufacturer"
                      value={stringEditValues.module_manufacturer}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      error={Boolean(stringEditErrors.module_manufacturer)}
                      helperText={stringEditErrors.module_manufacturer}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSaveEdit() : null
                      }
                      onBlur={() => {
                        if (!stringEditValues.module_manufacturer) {
                          changeDialogErrors(
                            "module_manufacturer",
                            "Module manufacturer is required"
                          );
                        } else {
                          changeDialogErrors("module_manufacturer", "");
                        }
                      }}
                    >
                      {Object.entries(moduleDropdown).map(([key, value]) => (
                        <MenuItem value={key}>{key}</MenuItem>
                      ))}
                    </TextField>
                  )}

                  {/* Module Type */}
                  {stringEditValues.module_manufacturer ===
                    "-- Manufacturer not on the list --" ||
                  stringEditValues.module_manufacturer_custom ||
                  stringEditValues.module_type ===
                    "-- Module type not on the list --" ||
                  stringEditValues.module_type_custom ? (
                    <TextField
                      label="Module Type"
                      name="module_type_custom"
                      value={stringEditValues.module_type_custom}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      error={Boolean(stringEditErrors.module_type_custom)}
                      helperText={stringEditErrors.module_type_custom}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSaveEdit() : null
                      }
                      onBlur={() => {
                        if (!stringEditValues.module_type_custom) {
                          stringEditValues.module_type = "";
                        } else {
                          changeDialogErrors("module_type", "");
                        }
                      }}
                    />
                  ) : (
                    <TextField
                      select
                      label="Module Type"
                      name="module_type"
                      value={stringEditValues.module_type}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      error={Boolean(stringEditErrors.module_type)}
                      helperText={stringEditErrors.module_type}
                      onKeyDown={(e) =>
                        e.key === "Enter" ? handleSaveEdit() : null
                      }
                      onBlur={() => {
                        if (!stringEditValues.module_type) {
                          changeDialogErrors(
                            "module_type",
                            "Module type is required"
                          );
                        } else {
                          changeDialogErrors("module_type", "");
                        }
                      }}
                    >
                      {Object.keys(moduleDropdown).includes(
                        stringEditValues.module_manufacturer
                      )
                        ? moduleDropdown[
                            stringEditValues.module_manufacturer
                          ].map((type) => (
                            <MenuItem value={type}>{type}</MenuItem>
                          ))
                        : null}
                    </TextField>
                  )}

                  <Typography
                    variant="h6"
                    style={{ textTransform: "none", marginTop: 10 }}
                  >
                    Optimizer
                  </Typography>

                  {/* Orientation */}
                  {/* Number of optimizer */}
                  <TextField
                    label="Number of Optimizers"
                    name="numoptimizer"
                    type="number"
                    value={stringEditValues.numoptimizer}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.numoptimizer)}
                    helperText={stringEditErrors.numoptimizer}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.numoptimizer &&
                        stringEditValues.numoptimizer < 0
                      ) {
                        changeDialogErrors(
                          "numoptimizer",
                          "Number of optimizer cannot be negative"
                        );
                      } else {
                        changeDialogErrors("numoptimizer", "");
                      }
                    }}
                  />

                  {/* Power per optimizer */}
                  <TextField
                    label="Power per Optimizer in W"
                    name="dc_power_optimizer"
                    type="number"
                    value={stringEditValues.dc_power_optimizer}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.dc_power_optimizer)}
                    helperText={stringEditErrors.dc_power_optimizer}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">W</InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.dc_power_optimizer &&
                        stringEditValues.dc_power_optimizer < 0
                      ) {
                        changeDialogErrors(
                          "dc_power_optimizer",
                          "DC power of the optimizer cannot be negative"
                        );
                      } else {
                        changeDialogErrors("dc_power_optimizer", "");
                      }
                    }}
                  />

                  {/* Optimizer manufacturer */}
                  <TextField
                    label="Optimizer Manufacturer"
                    name="optimizer_manufacturer"
                    value={stringEditValues.optimizer_manufacturer}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.optimizer_manufacturer)}
                    helperText={stringEditErrors.optimizer_manufacturer}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                  />

                  {/* Optimizer type */}
                  <TextField
                    label="Optimizer Type"
                    name="optimizer_type"
                    value={stringEditValues.optimizer_type}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.optimizer_type)}
                    helperText={stringEditErrors.optimizer_type}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                  />

                  <Typography
                    variant="h6"
                    style={{ textTransform: "none", marginTop: 10 }}
                  >
                    Optional Information
                  </Typography>

                  {/* Orientation */}
                  <TextField
                    label="Orientation in degrees"
                    name="orientation"
                    type="number"
                    value={stringEditValues.orientation}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.orientation)}
                    helperText={stringEditErrors.orientation}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.orientation &&
                        stringEditValues.orientation < 0
                      ) {
                        changeDialogErrors(
                          "orientation",
                          "Orientation cannot be negative"
                        );
                      } else {
                        changeDialogErrors("orientation", "");
                      }
                    }}
                  />

                  {/* Tilt */}
                  <TextField
                    label="Tilt in degrees"
                    name="tilt"
                    type="number"
                    value={stringEditValues.tilt}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.tilt)}
                    helperText={stringEditErrors.tilt}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (stringEditValues.tilt && stringEditValues.tilt < 0) {
                        changeDialogErrors("tilt", "Tilt cannot be negative");
                      } else if (
                        stringEditValues.tilt &&
                        stringEditValues.tilt > 90
                      ) {
                        changeDialogErrors(
                          "tilt",
                          "Tilt cannot be greater than 90"
                        );
                      } else {
                        changeDialogErrors("tilt", "");
                      }
                    }}
                  />

                  <Typography variant="body1">
                    Orientation is south-based, so 0 degrees is South, 90
                    degrees is West, 180 North, 270 East... The tilt goes from 0
                    to 90, where 0 means the module is parallel to the ground
                    and 90 means it "stands upright".
                  </Typography>

                  {/* Mounting Type */}
                  <TextField
                    label="Mounting Type"
                    name="mounting_type"
                    value={stringEditValues.mounting_type}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.mounting_type)}
                    helperText={stringEditErrors.mounting_type}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                  />

                  {/* Module cayble length */}
                  <TextField
                    label="Module cable length in m"
                    name="module_cable_length"
                    type="number"
                    value={stringEditValues.module_cable_length}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.module_cable_length)}
                    helperText={stringEditErrors.module_cable_length}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.module_cable_length &&
                        stringEditValues.module_cable_length < 0
                      ) {
                        changeDialogErrors(
                          "module_cable_length",
                          "Module cable length cannot be negative"
                        );
                      } else {
                        changeDialogErrors("module_cable_length", "");
                      }
                    }}
                  />

                  {/* Module cable cross section */}
                  <TextField
                    label="Module cable cross section in mm²"
                    name="module_cable_cross_section"
                    type="number"
                    value={stringEditValues.module_cable_cross_section}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.module_cable_cross_section)}
                    helperText={stringEditErrors.module_cable_cross_section}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm²</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.module_cable_cross_section &&
                        stringEditValues.module_cable_cross_section < 0
                      ) {
                        changeDialogErrors(
                          "module_cable_cross_section",
                          "Module cable cross section cannot be negative"
                        );
                      } else {
                        changeDialogErrors("module_cable_cross_section", "");
                      }
                    }}
                  />

                  {/* MPP Voltage */}
                  <TextField
                    label="MPP Voltage in V"
                    name="voltage_mpp"
                    type="number"
                    value={stringEditValues.voltage_mpp}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.voltage_mpp)}
                    helperText={stringEditErrors.voltage_mpp}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">V</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.voltage_mpp &&
                        stringEditValues.voltage_mpp < 0
                      ) {
                        changeDialogErrors(
                          "voltage_mpp",
                          "MPP voltage cannot be negative"
                        );
                      } else {
                        changeDialogErrors("voltage_mpp", "");
                      }
                    }}
                  />

                  {/* OC Voltage */}
                  <TextField
                    label="Open Circuit Voltage in V"
                    name="voltage_oc"
                    type="number"
                    value={stringEditValues.voltage_oc}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.voltage_oc)}
                    helperText={stringEditErrors.voltage_oc}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">V</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.voltage_oc &&
                        stringEditValues.voltage_oc < 0
                      ) {
                        changeDialogErrors(
                          "voltage_oc",
                          "Open Circuit voltage cannot be negative"
                        );
                      } else {
                        changeDialogErrors("voltage_oc", "");
                      }
                    }}
                  />

                  {/* Optimizer mounting place */}
                  <TextField
                    label="Optimizer Mounting Place"
                    name="optimizer_mounting_place"
                    value={stringEditValues.optimizer_mounting_place}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.optimizer_mounting_place)}
                    helperText={stringEditErrors.optimizer_mounting_place}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                  />

                  {/* optimizer cayble length */}
                  <TextField
                    label="Optimizer cable length in m"
                    name="optimizer_cable_length"
                    type="number"
                    value={stringEditValues.optimizer_cable_length}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(stringEditErrors.optimizer_cable_length)}
                    helperText={stringEditErrors.optimizer_cable_length}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.optimizer_cable_length &&
                        stringEditValues.optimizer_cable_length < 0
                      ) {
                        changeDialogErrors(
                          "optimizer_cable_length",
                          "optimizer cable length cannot be negative"
                        );
                      } else {
                        changeDialogErrors("optimizer_cable_length", "");
                      }
                    }}
                  />

                  {/* optimizer cable cross section */}
                  <TextField
                    label="Optimizer cable cross section in mm²"
                    name="optimizer_cable_cross_section"
                    type="number"
                    value={stringEditValues.optimizer_cable_cross_section}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={Boolean(
                      stringEditErrors.optimizer_cable_cross_section
                    )}
                    helperText={stringEditErrors.optimizer_cable_cross_section}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSaveEdit() : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm²</InputAdornment>
                      ),
                    }}
                    sx={{
                      "input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    }}
                    onBlur={() => {
                      if (
                        stringEditValues.optimizer_cable_cross_section &&
                        stringEditValues.optimizer_cable_cross_section < 0
                      ) {
                        changeDialogErrors(
                          "optimizer_cable_cross_section",
                          "Optimizer cable cross section cannot be negative"
                        );
                      } else {
                        changeDialogErrors("optimizer_cable_cross_section", "");
                      }
                    }}
                  />
                </form>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  <div>
                    <Typography variant="h6">String Information:</Typography>
                    <p>
                      <strong>String Name: </strong>
                      {string.name}
                    </p>
                    <p>
                      <strong>String DC Power: </strong>
                      {string.dc_power / 1000} kW
                    </p>
                  </div>

                  <div>
                    <Typography variant="h6">Module Information:</Typography>
                    <p>
                      <strong>Number of Modules: </strong>
                      {string.nummodules}
                    </p>
                    <p>
                      <strong>Power per Module: </strong>
                      {string.dc_power_per_module} W
                    </p>
                    <p>
                      <strong>Module Manufacturer: </strong>
                      {string.module_manufacturer}
                    </p>
                    <p>
                      <strong>Module Type: </strong>
                      {string.module_type}
                    </p>
                    {string.orientation ? (
                      <p>
                        <strong>Orientation in degrees: </strong>
                        {string.orientation}
                      </p>
                    ) : null}
                    {string.tilt ? (
                      <p>
                        <strong>Tilt in degrees: </strong>
                        {string.tilt}
                      </p>
                    ) : null}
                    {string.mounting_type ? (
                      <p>
                        <strong>Mounting type: </strong>
                        {string.mounting_type}
                      </p>
                    ) : null}
                    {string.module_cable_length ? (
                      <p>
                        <strong>Module cable length: </strong>
                        {string.module_cable_length} m
                      </p>
                    ) : null}
                    {string.module_cable_cross_section ? (
                      <p>
                        <strong>Module cable cross section: </strong>
                        {string.module_cable_cross_section} mm²
                      </p>
                    ) : null}
                    {string.voltage_mpp ? (
                      <p>
                        <strong>MPP voltage: </strong>
                        {string.voltage_mpp} V
                      </p>
                    ) : null}
                    {string.voltage_oc ? (
                      <p>
                        <strong>Open Circuit Voltage: </strong>
                        {string.voltage_oc} V
                      </p>
                    ) : null}
                  </div>
                  <div>
                    {string.numoptimizer ||
                    string.dc_power_optimizer ||
                    string.optimizer_manufacturer ||
                    string.optimizer_cable_cross_section ||
                    string.optimizer_cable_length ||
                    string.optimizer_mounting_place ||
                    string.optimizer_type ? (
                      <Typography
                        variant="h6"
                        style={{ textTransform: "none" }}
                      >
                        Optimizer Information
                      </Typography>
                    ) : null}
                    <div>
                      {string.numoptimizer ? (
                        <p>
                          <strong>Number of Optimizers: </strong>
                          {string.numoptimizer}
                        </p>
                      ) : null}
                      {string.dc_power_optimizer ? (
                        <p>
                          <strong>Power per Optimizer: </strong>
                          {string.dc_power_optimizer} W
                        </p>
                      ) : null}
                      {string.optimizer_manufacturer ? (
                        <p>
                          <strong>Optimizer Manufacturer: </strong>
                          {string.optimizer_manufacturer}
                        </p>
                      ) : null}
                      {string.optimizer_type ? (
                        <p>
                          <strong>Optimizer Type: </strong>
                          {string.optimizer_type}
                        </p>
                      ) : null}
                      {string.optimizer_mounting_place ? (
                        <p>
                          <strong>Optimizer mounting place: </strong>
                          {string.optimizer_mounting_place}
                        </p>
                      ) : null}
                      {string.optimizer_cable_length ? (
                        <p>
                          <strong>Optimizer cable Length: </strong>
                          {string.optimizer_cable_length} m
                        </p>
                      ) : null}
                      {string.optimizer_cable_cross_section ? (
                        <p>
                          <strong>Optimizer cable cross section: </strong>
                          {string.optimizer_cable_cross_section} mm²
                        </p>
                      ) : null}
                    </div>
                  </div>
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* String management card */}
          <Card style={{ maxWidth: 1200, margin: "20px auto" }} elevation={5}>
            <CardContent>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                }}
              >
                {/* Button to go back to the inverter details */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      navigate(`/plants/${id}/inverter/${inverterId}`);
                    }}
                  >
                    Back to Inverter
                  </Button>
                </Grid>

                {/* Button to add another string */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {inverter.status === "operational" ? (
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => {
                        setIsCreateStringDialogOpen(true);
                      }}
                    >
                      Add another String
                    </Button>
                  ) : null}
                </Grid>

                {/* Button duplicate the string */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {inverter.status === "operational" ? (
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => {
                        duplicateString(string, true);
                      }}
                    >
                      Duplicate String
                    </Button>
                  ) : null}
                </Grid>

                {/* Delte string button */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete String
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default StringDetails;
