import React, { useState } from "react";
import {
  Button,
  Dialog,
  TextField,
  Grid,
  Box,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import apiService from "../services/apiService";

import { useNavigate } from "react-router-dom";

const CreatePlantDialog = ({ isOpen, onClose }) => {
  const [plantData, setPlantData] = React.useState({
    name: "",
    commissioning_date: "",
    location: "",
    role: "",
    // Add more fields as needed
  });

  const navigate = useNavigate();

  const [plantCreateDialogErrors, setPlantCreateDialogErrors] = useState({});

  const handleInputChange = (event) => {
    /*
    This function gets called each time the input of a
    Textfield gets changed. It changes the stored value for 
    a field to the input of the textfield
    */
    const { name, value } = event.target;
    setPlantData({ ...plantData, [name]: value });
  };

  const handleFormSubmit = async () => {
    /*
    Creates a new plant with the data from the form
    */
    const formIsReady = checkPlantCreateDialogErrors();
    if (formIsReady) {
      // use apiService to create a new plant
      var response = await apiService.createPlant(plantData);

      // Code for wrong locations, gets ignored at the moment
      // Locations that cannot be found simply do not generate the map
      if (response.message === "location not found") {
        const errors = {};
        errors.location_street = "Location not found";
        errors.location_zip = "Location not found";
        errors.location_city = "Location not found";
        setPlantCreateDialogErrors(errors);
        return;
      }

      // wait for the response
      if (response?.id) {
        // If the response is valid, close the dialog
        onClose();

        // Navigate to the newly created plant
        navigate(`/plants/${response.id}`);
      } else {
        // If the response is invalid, log the error
        console.error("Invalid response format. 'plant' property not found.");
      }
    } else {
      console.log("There are errors in the form");
      console.log(plantCreateDialogErrors);
    }
  };

  const checkPlantCreateDialogErrors = () => {
    /*
    Checks if the input of the form values are valid.
    Sets the error below the fields if it is not the case.
    */
    const errors = {};

    if (!plantData.name) {
      errors.name = "Plant name is required";
    }
    if (!plantData.commissioning_date) {
      errors.commissioning_date = "Commissioning date is required";
    } else if (
      !plantData.commissioning_date.match(/^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/)
    ) {
      errors.commissioning_date = "Date not valid";
    } else if (
      (() => {
        const [day, month, year] = plantData.commissioning_date.split(".");
        const isoDateString = `${year}-${month}-${day}`;
        const date1 = new Date(isoDateString);

        const date2 = new Date();

        return date1 > date2;
      })()
    ) {
      errors.commissioning_date = "Date must not lie in the future";
    }
    if (!plantData.location_street) {
      errors.location_street = "Street is required";
    }
    if (!plantData.location_zip) {
      errors.location_zip = "ZIP code is required";
    }
    if (!plantData.location_city) {
      errors.location_city = "City is required";
    }
    if (!plantData.location_country) {
      errors.location_country = "Country is required";
    }
    if (!plantData.role) {
      errors.role = "Role is required";
    }
    setPlantCreateDialogErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const changeDialogErrors = (name, value) => {
    setPlantCreateDialogErrors((errors) => ({
      ...errors,
      [name]: value,
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Plant</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Plant name */}
          <Grid item xs={12}>
            <Box sx={{ height: 10 }} />
            <TextField
              fullWidth
              label="Plant Name"
              name="name"
              value={plantData.name}
              onChange={handleInputChange}
              error={Boolean(plantCreateDialogErrors.name)}
              helperText={plantCreateDialogErrors.name}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.name) {
                  changeDialogErrors("name", "Plant name is required");
                } else {
                  changeDialogErrors("name", "");
                }
              }}
            />
          </Grid>

          {/* Commisioning Date */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Commisioning Date (DD.MM.YYYY)"
              name="commissioning_date"
              value={plantData.commissioning_date}
              onChange={handleInputChange}
              error={Boolean(plantCreateDialogErrors.commissioning_date)}
              helperText={plantCreateDialogErrors.commissioning_date}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.commissioning_date) {
                  changeDialogErrors(
                    "commissioning_date",
                    "Commissioning date is required"
                  );
                } else if (
                  !plantData.commissioning_date.match(
                    /^\d{1,2}[./-]\d{1,2}[./-]\d{4}$/
                  )
                ) {
                  changeDialogErrors("commissioning_date", "Date not valid");
                } else if (
                  (() => {
                    const [day, month, year] =
                      plantData.commissioning_date.split(".");
                    const isoDateString = `${year}-${month}-${day}`;
                    const date1 = new Date(isoDateString);

                    const date2 = new Date();

                    return date1 > date2;
                  })()
                ) {
                  changeDialogErrors(
                    "commissioning_date",
                    "Date must not lie in the future"
                  );
                } else {
                  changeDialogErrors("commissioning_date", "");
                }
              }}
            />
          </Grid>

          {/* Street Housenumber */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Street Housenumber"
              name="location_street"
              value={plantData.location_street}
              onChange={handleInputChange}
              error={Boolean(plantCreateDialogErrors.location_street)}
              helperText={plantCreateDialogErrors.location_street}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.location_street) {
                  changeDialogErrors("location_street", "Street is required");
                } else {
                  changeDialogErrors("location_street", "");
                }
              }}
            />
          </Grid>

          {/* ZIP Code of the location */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="ZIP"
              name="location_zip"
              value={plantData.location_zip}
              onChange={handleInputChange}
              error={Boolean(plantCreateDialogErrors.location_zip)}
              helperText={plantCreateDialogErrors.location_zip}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.location_zip) {
                  changeDialogErrors("location_zip", "ZIP code is required");
                } else {
                  changeDialogErrors("location_zip", "");
                }
              }}
            />
          </Grid>

          {/* City of the location */}
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              label="City"
              name="location_city"
              value={plantData.location_city}
              onChange={handleInputChange}
              error={Boolean(plantCreateDialogErrors.location_city)}
              helperText={plantCreateDialogErrors.location_city}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.location_city) {
                  changeDialogErrors("location_city", "City is required");
                } else {
                  changeDialogErrors("location_city", "");
                }
              }}
            />
          </Grid>

          {/* Country of the location */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Country"
              name="location_country"
              value={plantData.location_country}
              onChange={handleInputChange}
              error={Boolean(plantCreateDialogErrors.location_country)}
              helperText={plantCreateDialogErrors.location_country}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.location_country) {
                  changeDialogErrors("location_country", "Country is required");
                } else {
                  changeDialogErrors("location_country", "");
                }
              }}
            />
          </Grid>

          {/* Role of the user */}
          <Grid item xs={12}>
            <TextField
              select
              label="Role"
              name="role"
              value={plantData.role}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              displayEmpty // Add this prop to display the empty value
              error={Boolean(plantCreateDialogErrors.role)}
              helperText={plantCreateDialogErrors.role}
              onKeyDown={(e) => (e.key === "Enter" ? handleFormSubmit() : null)}
              onBlur={() => {
                if (!plantData.role) {
                  changeDialogErrors("role", "Role is required");
                } else {
                  changeDialogErrors("role", "");
                }
              }}
            >
              <MenuItem value={"Private Owner"}>Private Owner</MenuItem>
              <MenuItem value={"Professional Owner"}>
                Professional Owner
              </MenuItem>
              <MenuItem value={"Professional Installer"}>
                Professional Installer
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {/* Submit and Cancel buttons positioned with spacing in between */}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button
              color="secondary"
              onClick={() => {
                setPlantCreateDialogErrors({});
                onClose();
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePlantDialog;
