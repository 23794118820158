import React, { useState, useEffect } from "react";
import { Card, Grid, CardContent, Typography, Box } from "@mui/material";
import apiService from "../../services/apiService";

import AnalyticsIcon from "@mui/icons-material/Analytics";

function AdminStats() {
  const [stats, setStats] = useState(null);

  // Fetches the statistics
  const fetchAdminStats = async () => {
    try {
      const response = await apiService.getAdminStats();
      setStats(response["stats"]);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  useEffect(() => {
    fetchAdminStats();
  }, []);

  return (
    <Card elevation={5}>
      {stats && (
        <CardContent display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h5">
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                "align-items": "center",
              }}
            >
              <AnalyticsIcon />
              <Box style={{ marginLeft: "10px" }}>Website Statistics</Box>
            </Box>
          </Typography>

          <Grid
            container
            spacing={0}
            style={{ maxWidth: 800, margin: "auto", padding: 20 }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                "align-items": "center",
              }}
            >
              <Typography>
                <strong>Number of Users: </strong>
                {stats.number_of_users}
              </Typography>
              <Typography>
                <strong>Active Plants: </strong>
                {stats.plant_amount}
              </Typography>
              <Typography>
                <strong>Total Plants: </strong>
                {stats.plants_total_len}
              </Typography>
              <Typography>
                <strong>Active Inverters: </strong>
                {stats.inverter_amount}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                "align-items": "center",
              }}
            >
              <Typography>
                <strong>Number of strings: </strong>
                {stats.string_amount}
              </Typography>
              <Typography>
                <strong>Number of modules: </strong>
                {stats.module_amount}
              </Typography>
              <Typography>
                <strong>Total AC Power: </strong>
                {stats.total_ac_power > 1000
                  ? Math.round(
                      (stats.total_ac_power / 1000 + Number.EPSILON) * 10
                    ) / 10
                  : Math.round((stats.total_ac_power + Number.EPSILON) * 10) /
                    10}
                {stats.total_ac_power > 1000 ? " MW" : " kW"}
              </Typography>
              <Typography>
                <strong>Total DC Power: </strong>
                {stats.total_dc_power > 1000
                  ? Math.round(
                      (stats.total_dc_power / 1000 + Number.EPSILON) * 10
                    ) / 10
                  : Math.round((stats.total_dc_power + Number.EPSILON) * 10) /
                    10}
                {stats.total_dc_power > 1000 ? " MW" : " kW"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}

export default AdminStats;
