import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";

// needed for leaflet to work
import "leaflet/dist/leaflet.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
