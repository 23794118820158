import React, { useState, useEffect } from "react";

import { Typography, Grid, Box } from "@mui/material";
import apiService from "../services/apiService";
import NewTypeCard from "../components/Admin/NewTypeCard";
import RoleManagementGrid from "../components/Admin/RoleManagementGrid";
import AdminStats from "../components/Admin/AdminStatsCard";
import KaplanMeier from "../components/KaplanMeier";
import MapAdmin from "../components/Admin/MapAdmin";
import PlantControlGrid from "../components/Admin/PlantControlGrid";

import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";

function Admin() {
  // Current User
  const [currentUser, setCurrentUser] = useState({});
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiService.getAuth();

        if (response && response.hasOwnProperty("user")) {
          setCurrentUser(response.user);
        } else {
          console.error("Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching InverterTypes:", error);
      }
    };

    fetchUser();
  }, []);

  return currentUser.user_role === "admin" ? (
    <Grid
      container
      spacing={2}
      style={{ maxWidth: 800, margin: "auto", padding: 20 }}
    >
      <Grid item xs={12}>
        <MapAdmin height="400px" width="100%" />
      </Grid>

      <Grid item xs={12}>
        <KaplanMeier />
      </Grid>

      <Grid item xs={12}>
        <AdminStats />
      </Grid>

      {/* Role management */}
      <Grid item xs={12}>
        <RoleManagementGrid />
      </Grid>

      {/* Plant management */}
      <Grid item xs={12}>
        <PlantControlGrid />
      </Grid>

      {/* Inverter Type Management */}
      <Grid item xs={12}>
        <NewTypeCard
          title={
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                "align-items": "center",
              }}
            >
              <ElectricalServicesIcon />
              <Box style={{ marginLeft: "10px" }}>Inverter Type Management</Box>
            </Box>
          }
          fetchNewTypes={apiService.getInverterTypes}
          createNewType={apiService.createInverterType}
          specifier={"Inverter"}
        />
      </Grid>

      {/* Module Type Management */}
      <Grid item xs={12}>
        <NewTypeCard
          title={
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                "align-items": "center",
              }}
            >
              <SolarPowerIcon />
              <Box style={{ marginLeft: "10px" }}>Module Type Management</Box>
            </Box>
          }
          fetchNewTypes={apiService.getModuleTypes}
          createNewType={apiService.createModuleType}
          specifier={"Module"}
        />
      </Grid>
    </Grid>
  ) : (
    <Typography variant="h5">No Access</Typography>
  );
}

export default Admin;
