import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  MenuItem,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

const NewTypeCard = ({ title, fetchNewTypes, createNewType, specifier }) => {
  const gridApi = useGridApiRef();

  const [customTypes, setCustomTypes] = useState([]);
  const [oldTypes, setOldTypes] = useState({});
  const [newType, setNewType] = useState({
    manufacturer: "",
    type: "",
  });

  const fetchCustomTypes = async () => {
    try {
      const response = await fetchNewTypes();

      if (
        response &&
        response.hasOwnProperty("new_types") &&
        response.hasOwnProperty("types")
      ) {
        setCustomTypes(response.new_types);
        setOldTypes(response.types);
      } else {
        console.error("Invalid response format.");
      }
    } catch (error) {
      console.error("Error fetching Types:", error);
    }
  };

  useEffect(() => {
    fetchCustomTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewTypeInputChange = async (e) => {
    const { name, value } = e.target;

    // same logic as in create inverter for the
    // possibility to manually add a new type
    if (name === "manufacturer_custom" && !value) {
      setNewType((prev) => ({
        ...prev,
        manufacturer: "",
        manufacturer_custom: "",
        type: "",
        type_custom: "",
      }));
    }
    if ((name === "type_custom" && !value) || name === "manufacturer") {
      setNewType((prev) => ({
        ...prev,
        type: "",
        type_custom: "",
      }));
    }

    setNewType((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNewType = async () => {
    // gets the index of all selected rows
    const ids = getSelectedRows();

    // only the selected types are kept
    const toChange = customTypes.filter((type) => ids.includes(type.id));

    if (newType.manufacturer_custom) {
      newType.manufacturer = newType.manufacturer_custom;
    }
    if (newType.type_custom) {
      newType.type = newType.type_custom;
    }
    if (!newType.manufacturer || !newType.type) {
      console.error("Fields must be selected");
      return;
    }

    const createObject = {
      new_type: newType,
      to_change: toChange,
    };

    // creates the new type
    try {
      const response = await createNewType(createObject);

      if (response) {
        setNewType({});
        console.log("Created a new type successfully");
        fetchCustomTypes();
      } else {
        console.error("Invalid response when creating the type.");
      }
    } catch (error) {
      console.error("Error creating new type:", error);
    }
  };

  const getSelectedRows = () => {
    // Gets the ids of all selecte rows
    return Array.from(gridApi.current.getSelectedRows().keys());
  };

  // columns for the DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 130,
    },
    { field: "type", headerName: "Type", width: 130 },
  ];

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            "align-items": "center",
          }}
        >
          {/* Grid of new types */}
          <Grid item xs={12}>
            <DataGrid
              rows={customTypes}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              disableRowSelectionOnClick
              apiRef={gridApi}
            ></DataGrid>
          </Grid>

          {/* Manufacturer Field */}
          <Grid item xs={12} sm={4}>
            {newType.manufacturer === "-- Manufacturer not on the list --" ||
            newType.manufacturer_custom ? (
              <TextField
                label="Manufacturer"
                name="manufacturer_custom"
                value={newType.manufacturer_custom}
                onChange={handleAddNewTypeInputChange}
                fullWidth
                margin="normal"
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddNewType() : null
                }
              />
            ) : (
              <TextField
                select
                label="Manufacturer"
                name="manufacturer"
                value={newType.manufacturer}
                onChange={handleAddNewTypeInputChange}
                fullWidth
                margin="normal"
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddNewType() : null
                }
              >
                {Object.entries(oldTypes).map(([key, value]) => (
                  <MenuItem value={key}>{key}</MenuItem>
                ))}
              </TextField>
            )}
          </Grid>

          {/* Type Field */}
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            {newType.manufacturer === "-- Manufacturer not on the list --" ||
            newType.manufacturer_custom ||
            newType.type === "-- " + specifier + " type not on the list --" ||
            newType.type_custom ? (
              <TextField
                label="Type"
                name="type_custom"
                value={newType.type_custom}
                onChange={handleAddNewTypeInputChange}
                fullWidth
                margin="normal"
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddNewType() : null
                }
              />
            ) : (
              <TextField
                select
                label="Type"
                name="type"
                value={newType.type}
                onChange={handleAddNewTypeInputChange}
                fullWidth
                margin="normal"
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleAddNewType() : null
                }
              >
                {Object.keys(oldTypes).includes(newType.manufacturer)
                  ? oldTypes[newType.manufacturer].map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))
                  : null}
              </TextField>
            )}
          </Grid>

          {/* Submit button*/}
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <Button variant="contained" color="info" onClick={handleAddNewType}>
              Add new Type
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NewTypeCard;
