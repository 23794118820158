import React from "react";

import { Typography, Link } from "@mui/material";

function About() {
  return (
    <div style={{ maxWidth: 800, margin: "auto", padding: 20 }}>
      <Typography variant="h6">Who we are</Typography>
      <Typography variant="body1">
        This site was created and is maintained by the{" "}
        <Link
          href="https://www.bfh.ch/pvlab"
          underline="hover"
          target="_blank"
          rel="noreferrer"
        >
          {"Laboratory for Photovoltaic Systems"}
        </Link>{" "}
        at the Bern University of applied sciences. The PV-Lab has specialized
        in different areas of photovoltaic research. One of the main areas is
        the testing of inverters, which is the reason this website was created.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        What our goal is
      </Typography>
      <Typography variant="body1">
        In 2022 the study "LIFE EXPECTANCY OF PV INVERTERS AND OPTIMIZERS IN
        RESIDENTIAL PV SYSTEMS" was conducted by our laboratory. As in this
        study, the main goal is to find an estimate of the average lifetime of
        inverters. Furthermore, using additional information about the
        inverters, for instance, the place of installation, it is also possible
        to find good practices in the installation and maintenance of inverters
        which are conducive to a long and trouble-free inverter lifetime.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        What's this website for
      </Typography>
      <Typography variant="body1">
        We want to gather information about the lifetime of inverters from
        various sources. This includes private photovoltaic plants as well as
        big plants. Using this website, we will be able to collect the data in a
        more systematic and orderly way. In addition, it does not require us to
        actively search for photovoltaic plant owners, which means the data is
        passively collected.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        Why we need you
      </Typography>
      <Typography variant="body1">
        To make significant conclusions about the lifetime of inverters it is
        necessary to have as much data as possible. The more data we have, the
        preciser the result will be. Thus, we need as much photovoltaic plant
        owners as possible to contribute; every additional information about an
        inverter we can get counts.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        Why you should contribute
      </Typography>
      <Typography variant="body1">
        First, you get to participate in this study and advance the knowledge of
        photovoltaic research in the field of inverters. Second, our website
        neatly structures your plants, inverters and more, which can help you
        keep your photovoltaic plants organized. In addition, we also neatly
        summarize your plants in a statistics tab, where you can see the total
        power of your plant and more.
      </Typography>
      <Typography variant="h6" marginTop={2}>
        Feedback Form
      </Typography>
      <Typography variant="body1">
        Currently, we are still in a testing phase of the website and we would
        thus kindly ask you to fill out the{" "}
        <Link
          href="https://forms.office.com/e/bTLgxM4uUU"
          underline="hover"
          target="_blank"
          rel="noreferrer"
        >
          {"Feedback form"}
        </Link>
        {", "}
        such that we can further improve the site.
      </Typography>
    </div>
  );
}

export default About;
