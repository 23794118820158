import React from "react";
import {
  Typography,
  Card,
  Grid,
  Box,
  CardActionArea,
  CardContent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Link } from "react-router-dom";

const StringsGrid = ({
  strings,
  setIsCreateStringDialogOpen,
  inverterStatus,
}) => {
  /*
  Creates a Grid for the Strings. The grid consists of cards with some
  information about the string on it. The cards link to the detail page of the 
  string and there is an empty card with a plus sign on it to add new strings.
  */
  const cardHeight = 130;
  return (
    <>
      {/* Strings */}
      <Box sx={{ height: 20 }} />
      <Grid container spacing={2}>
        {strings.map((string, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card elevation={3} style={{ height: cardHeight }}>
              <CardContent>
                <Link
                  to={`/plants/${string.pv_plant_id}/inverter/${string.inverter_id}/strings/${string.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    noWrap
                    variant="h6"
                    component="div"
                    style={{ marginBottom: 8 }}
                  >
                    {string.name}{" "}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: 8 }}
                    >
                      <Grid item xs={12}>
                        <strong>Nominal DC Power: </strong>
                        {string.dc_power / 1000} kW
                      </Grid>
                      <Grid item xs={12}>
                        <strong>Number of modules: </strong>
                        {string.nummodules}
                      </Grid>
                      <Grid item xs={12}>
                        <strong>Number of Optimizer: </strong>
                        {string.numoptimizer}
                      </Grid>
                    </Grid>
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {inverterStatus === "operational" ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              elevation={3}
              style={{
                height: cardHeight,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardActionArea
                onClick={() => {
                  setIsCreateStringDialogOpen(true);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CardContent>
                  <AddIcon fontSize="large" />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default StringsGrid;
