import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Checkbox,
} from "@mui/material";
import apiService from "../../services/apiService";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PlantControlGrid = () => {
  const gridApi = useGridApiRef();

  const [plants, setPlants] = useState([]);

  const [onlyZeroPower, setOnlyZeroPower] = useState(false);
  const [onlyTestInName, setOnlyTestInName] = useState(false);
  const [showVisible, setShowVisible] = useState(true);
  const [showInvisible, setShowInisible] = useState(true);

  const fetchPlants = async () => {
    try {
      const response = await apiService.getAllPlants();

      if (response && response.hasOwnProperty("plants")) {
        setPlants(response.plants);
      } else {
        console.error("Invalid response format. 'plants' property not found.");
      }
    } catch (error) {
      console.error("Error fetching plants:", error);
    }
  };

  useEffect(() => {
    fetchPlants();
  }, []);

  const handlePlantManagementSubmit = async () => {
    // gets the index of all selected rows
    const ids = getSelectedRows();
    const toChange = plants.filter((plant) => ids.includes(plant.id));

    // only the selected types are kept

    toChange.forEach((plant) => {
      togglePlant(plant);
    });
  };

  const togglePlant = async (plantToChange) => {
    /* 
    Toggles the visibility of the selected plant
    */
    try {
      console.log(plantToChange);
      const response = await apiService.togglePlantVisibility(plantToChange.id);

      if (response && response.hasOwnProperty("id")) {
        console.log("Switched visibility");
        fetchPlants();
      } else {
        console.error("Invalid response when toggling visibility.");
      }
    } catch (error) {
      console.error("Error creating invert type:", error);
    }
  };

  const getSelectedRows = () => {
    // Gets the ids of all selecte rows
    return Array.from(gridApi.current.getSelectedRows().keys());
  };

  // columns for the DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 120 },
    {
      field: "ac_power",
      headerName: "AC Power in [kW]",
      width: 120,
    },
    {
      field: "dc_power",
      headerName: "DC Power [kW]",
      width: 120,
    },
    {
      field: "incorporate_to_statistics",
      headerName: "Visible",
      width: 120,
    },
  ];

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant="h5">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <VisibilityOffIcon />
            <Box style={{ marginLeft: "10px" }}>Plant Visibility</Box>
          </Box>
        </Typography>

        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            "align-items": "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={onlyZeroPower}
              sx={{ color: "green" }}
              onClick={() => {
                if (!onlyZeroPower) {
                  setOnlyTestInName(false);
                }
                setOnlyZeroPower(!onlyZeroPower);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Only show zero power plants
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={onlyTestInName}
              sx={{ color: "green" }}
              onClick={() => {
                if (!onlyTestInName) {
                  setOnlyZeroPower(false);
                }
                setOnlyTestInName(!onlyTestInName);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Only show plants containing "test"
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={showVisible}
              sx={{ color: "green" }}
              onClick={() => {
                setShowVisible(!showVisible);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Show Visible plants
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={showInvisible}
              sx={{ color: "green" }}
              onClick={() => {
                setShowInisible(!showInvisible);
              }}
            />
            <Typography variant="body1" align="center" gutterBottom>
              Show invisible plants
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              rows={plants.filter(
                (plant) =>
                  ((plant.incorporate_to_statistics && showVisible) ||
                    (!plant.incorporate_to_statistics && showInvisible)) &&
                  ((onlyZeroPower &&
                    plant.ac_power === 0 &&
                    plant.dc_power === 0) ||
                    (onlyTestInName &&
                      plant.name.toLowerCase().includes("test")) ||
                    (!onlyTestInName && !onlyZeroPower))
              )}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              disableRowSelectionOnClick
              apiRef={gridApi}
            ></DataGrid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              "align-items": "center",
            }}
          >
            <Button
              variant="contained"
              color="info"
              onClick={handlePlantManagementSubmit}
            >
              Toggle visibility
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlantControlGrid;
