import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";

const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setZoom(13);
    map.setView([lat, lng]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);
  return null;
};

const Map = ({ height, width, marker }) => {
  const center = [marker.latitude, marker.longitude];

  // laod svg marker from /marker.svg
  const icon = L.icon({
    iconUrl: "/marker.svg",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });
  return (
    <MapContainer center={center} zoom={13} style={{ height, width }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* marker */}
      <Marker position={[marker.latitude, marker.longitude]} icon={icon}>
        <Popup>
          Name: {marker.name}
          <br />
          ID: {marker.id}
        </Popup>
      </Marker>

      {/* Recenters the map on coordinate changes */}
      <RecenterAutomatically lat={marker.latitude} lng={marker.longitude} />
    </MapContainer>
  );
};

export default Map;
